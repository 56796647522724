import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['taskItem', 'searchInput'];

  filterRecentTasks() {
    const search = this.searchInputTarget.value.trim().toLowerCase();

    if (search.length > 1) {
      this.taskItemTargets.forEach(item => {
        const taskName = item.dataset.taskName.toLowerCase();
        item.classList.toggle('d-none', !taskName.includes(search));
      });
    } else {
      this.taskItemTargets.forEach(item => {
        item.classList.remove('d-none');
      });
    }
  }
}
