import $ from 'jquery';
import { TasksPage } from './tasksPage';
import { ChargesPage } from './chargesPage';

export class Pages {
  static setup() {
    $(document).ready(() => {
      TasksPage.setup();
      Pages.setupTooltips();
      ChargesPage.setup();
    });
  }

  static setupTooltips() {
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
  }
}
