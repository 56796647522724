import $ from 'jquery';

export class ChargesPage {
  static setup() {
    $('.bootstrap_switch').change(function() {
      ChargesPage.on_switch_change($(this));
    });

    $('.check_all_table').click(function() {
      ChargesPage.select_all_table_column_checkboxes($(this), true);
    });

    $('.check_none_table').click(function() {
      ChargesPage.select_all_table_column_checkboxes($(this), false);
    });
  }

  static select_all_table_column_checkboxes(element, checked) {
    const parent_table = element.closest('table');
    const index = parent_table.find('th').index(element.closest('th')) + 1;
    return parent_table.find(`tr td:nth-child(${index})`).each(function() {
      const form_switch = $(this).find('.bootstrap_switch');
      if (!form_switch.prop('disabled')) {
        form_switch.prop('checked', checked);
        return form_switch.trigger('change');
      }
      return null;
    });
  }

  static on_switch_change(form_switch) {
    const charge = {};
    charge[form_switch.data('update-field')] = form_switch.prop('checked');
    return $.ajax({
      type: 'PUT',
      dataType: 'json',
      url: form_switch.data('update-state-url'),
      data: {
        charge
      },
      success(data, _status, _jqXHR) {
        form_switch.closest('td').find('.col-error').remove();
        if (data && data.errors) {
          form_switch.closest('td').prepend(`<div class='col-error' data-toggle='tooltip' data-placement='top' title='${data.errors[0]}'>` + 'Error ' + '<i class=\'fa fa-times-circle\'></i>' + '</div>');
          $('[data-toggle="tooltip"]').tooltip();
        }
      }
    });
  }
}
