import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['newTagButton', 'tagList'];

  handleNoResultsClick() {
    const match = this.newTagButtonTarget.innerText.match(/"([^"]*)"/);
    if (match) {
      const newTag = match[1];
      this.tagListTarget.insertAdjacentHTML('beforeend', this.createTagOption(newTag));
      $(this.tagListTarget).selectpicker('refresh');
    }
  }

  createTagOption(newTag) {
    const content = `<span class='badge badge-primary'>${newTag}</span>`;
    return `<option value="${newTag}" selected="selected" data-content="${content}">${newTag}</option>`;
  }
}
