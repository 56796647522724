import { Controller } from '@hotwired/stimulus';
import countdown from 'countdown';

export default class extends Controller {
  static values = { started: String };

  static targets = ['timerRow', 'countdownHours', 'countdownMinutes', 'countdownSeconds'];

  connect() {
    if (this.startedValue) {  
      this.updateTimer();
      this.updateActiveTimers();
      this.startInterval();
    }
  }

  disconnect() {
    clearInterval(this.interval);
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.updateTimer();
      this.updateActiveTimers();
    }, 1000);
  }

  updateTimer() {
    countdown(new Date(this.startedValue), (ts) => {
      if (this.hasCountdownHoursTarget) {
        this.countdownHoursTarget.textContent = ts.hours || 0;
        this.countdownMinutesTarget.textContent = ts.minutes || 0;
        this.countdownSecondsTarget.textContent = ts.seconds || 0;
      }
    });
  }

  updateActiveTimers() {
    if (this.startedValue && this.hasTimerRowTarget) {
      countdown(new Date(this.startedValue), (ts) => {
        const text = `${ts.hours || 0} hours ${ts.minutes || 0} minutes ${ts.seconds || 0} seconds`;
        this.timerRowTarget.querySelector('.time-countdown').textContent = text;
      });
    }
  }
}
