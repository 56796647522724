import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['actualStart', 'actualEnd', 'adjustedStart', 'adjustedEnd'];

  initialize() {
    this.adjustedStartClicked = false;
    this.adjustedEndClicked = false;
  }

  connect() {
    if (this.hasAdjustedStartTarget) {
      this.originalAdjustedStartTime = this.adjustedStartTarget.value;
      this.originalAdjustedEndTime = this.adjustedEndTarget.value;
    }
  }

  fillAdjustedStartTime() {
    if (this.adjustedStartClicked || !this.hasActualStartTarget || this.originalAdjustedStartTime) {
      return;
    }

    this.adjustedStartClicked = true;

    const actualStartTime = this.actualStartTarget.value;
    if (actualStartTime) {
      this.adjustedStartTarget.value = actualStartTime;
    }
  }

  fillAdjustedEndTime() {
    if (this.adjustedEndClicked || !this.hasActualEndTarget || this.originalAdjustedEndTime) {
      return;
    }

    this.adjustedEndClicked = true;

    if (this.actualEndTarget.value) {
      this.adjustedEndTarget.value = this.actualEndTarget.value;
    }
  }
}
