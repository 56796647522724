import $ from 'jquery';
import { Toast } from 'rad_common_js/src/toast';

export class TasksPage {
  static addNewContainer(data, status, jqXHR, oldupdateContainer, _stopPropogation) {
    if ($.type(data) === 'object' && data.error) {
      return alert(data.error);
    }
    const newupdateContainer = $(data.html);
    oldupdateContainer.replaceWith(newupdateContainer);

    return null;
  }

    static retrieveNewContainer = function(updateContainer, value, stopPropogation) {
      if (stopPropogation == null) {
        stopPropogation = false;
      }
      const url = updateContainer.data('update-url');
      const data = updateContainer.data();
      const data_value = {};
      data_value[$(updateContainer).data('attribute')] = value;
      data[$(updateContainer).data('model')] = data_value;
      return $.ajax(url, {
        type: 'PUT',
        data,
        dataType: 'JSON',
        success(data, status, jqXHR) {
          Toast.success('Task updated!', 'Task was successfully updated.');
          return TasksPage.addNewContainer(data, status, jqXHR, updateContainer, stopPropogation);
        }
      });
    };

    static addTaskButtonEvents() {
      return $('.task-button').each(function() {
        return $(this).parent('.update-container').parent().on('click', '.task-button', function(e) {
          e.preventDefault();
          const updateContainer = $(this).parent('.update-container');
          return TasksPage.retrieveNewContainer(updateContainer, $(this).data('task-id'));
        });
      });
    }

    static addInputUpdateEvents() {
      return $('.input-update').each(function() {
        let event_type;
        event_type = '';
        if ($(this).hasClass('date')) {
          event_type = 'blur';
        } else if ($(this).attr('type') === 'checkbox') {
          event_type = 'click';
        } else {
          event_type = 'change';
        }
        return $(this).closest('.update-container').parent().on(event_type, '.input-update', function() {
          const updateContainer = $(this).closest('.update-container');
          let value = '';
          let value_changed = true;
          if ($(this).attr('type') === 'checkbox') {
            value = $(this).prop('checked');
          } else {
            value_changed = $(this).val() !== this.defaultValue;
            value = $(this).val();
          }
          if (value_changed) {
            return TasksPage.retrieveNewContainer(updateContainer, value);
          }

          return null;
        });
      });
    }

    static addUpdateEvents() {
      TasksPage.addTaskButtonEvents();
      TasksPage.addInputUpdateEvents();
    }

    static getIcon(data) {
      if (data === 'only_me') {
        return ['fa-lock', 'Only me'];
      } else if (data === 'only_admins') {
        return ['fa-user-tie', 'Only admins'];
      } else if (data === 'internal_users') {
        return ['fa-user-group', 'Internal users'];
      }

      return ['fa-globe', 'All users including clients'];
    }

    static setup() {
      TasksPage.addUpdateEvents();
      $(document).on('change', '#visibility_select', function(_event) {
        const icon = `#visibility_icon_${$(this).data().id.toString()}`;
        const tooltip = `#visibility_tooltip_${$(this).data().id.toString()}`;
        const visibility_type = $(this).val();
        const icon_data = TasksPage.getIcon(visibility_type);
        const icon_class = `fa fa-2x ${icon_data[0]}`;
        $.ajax({
          type: 'PUT',
          url: `${'/' + 'notes' + '/'}${$(this).data().id.toString()}`,
          data: {
            note: {
              visibility_type
            }
          },
          dataType: 'json',
          success(_data) {
            $(icon).removeClass();
            $(icon).addClass(icon_class);
            return $(tooltip).tooltip('hide').attr('data-original-title', icon_data[1]).tooltip('show');
          },
          error() {
            alert('An error has occurred while attempting to update task');
            return location.reload();
          }
        });
      });
    }
}
