import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clientSelect', 'amount'];

  clientChanged() {
    if (this.clientSelectTarget.value) {
      this.fetchClientBalance();
    }
  }

  fetchClientBalance() {
    fetch(`/clients/${this.clientSelectTarget.value}?attributes=balance`)
      .then(response => response.json())
      .then((data) => this.amountTarget.value = Number(data.balance));
  }
}
