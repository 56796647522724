import $ from 'jquery';

export class DynamicSelectable {
  constructor($select) {
    $select = $($select);
    this.urlTemplate = $select.data('dynamicSelectableUrl');
    this.$targetSelect = $($select.data('dynamicSelectableTarget'));
    return $select.on('change', (function(_this) {
      return function() {
        _this.clearTarget();
        const url = _this.constructUrl($select.val());
        if (url) {
          return $.getJSON(url, data => {
            $.each(data, (index, el) => {
              _this.$targetSelect.append(`<option value='${el.id}'>${el.name}</option>`);
              return $('.selectpicker').selectpicker('refresh');
            });
            return _this.reinitializeTarget();
          });
        }
        return _this.reinitializeTarget();

      };
    }(this)));
  }

  reinitializeTarget() {
    return this.$targetSelect.trigger('change');
  }

  clearTarget() {
    return this.$targetSelect.html('<option></option>');
  }

  constructUrl(id) {
    if (id && id !== '') {
      return this.urlTemplate.replace(/:.+_id/, id).replace(/\%3A.+_id/, id);
    }

    return null;
  }

  static setup() {
    $(document).ready(() => {
      $('select[data-dynamic-selectable-url][data-dynamic-selectable-target]').each(function() {
        new DynamicSelectable(this);
      });
    });
  }
}
